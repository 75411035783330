@use 'styles/variables' as var;
@use 'styles/functions' as fn;
@use 'styles/images' as img;

.title-no-decoration {
  text-decoration: none !important;
  color: black;
}

.section {
  background-color: var.$color-neutral-0;
  padding-top: fn.spacing(6);
  padding-bottom: fn.spacing(6);
  max-width: 1000vh;
  overflow-x: hidden;
}

.card-link {
  color: black;
}

.card-link:hover {
  text-decoration: none;

  [data-testid='card-title'] {
    text-decoration: underline;
  }
}

.articles-carousel-wrapper {
  padding-left: 0 !important;
}

.title-divider {
  margin-bottom: fn.spacing(6);
}

.link {
  margin-top: fn.spacing(3);
  display: flex;
  justify-content: center;
}

.card-container {
  display: flex;
  flex-direction: column;
  max-width: 315px;
}

.card-title,
.card-sub-title,
.card-category {
  margin: fn.spacing(1.5) 0;
}

.card-category {
  color: var.$color-neutral-300;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  line-height: 16.6px;
}

.image-container {
  border-radius: var.$achieve-border-radius-2;
  display: block;
  overflow: hidden;
  margin-bottom: fn.spacing(2);
}

.img-container {
  @include img.img-container;

  width: 315px;
  height: 204px;

  span {
    @include img.img-span;
  }

  img {
    @include img.img-element;

    object-fit: cover;
    object-position: center center;
    border-radius: fn.spacing(1.5);
  }
}

.video-icon {
  width: 100%;
  height: 100%;

  img {
    min-width: 64px;
    min-height: 64px;
  }
}

@media screen and (min-width: var.$breakpoint-md) {
  .card-container {
    max-width: 384px;
  }

  .img-container {
    width: 390px;
    height: 212px;
  }
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .articles-carousel-wrapper {
    overflow: hidden;
  }

  .articles-carousel-items:first-child {
    padding-left: 0 !important;
  }

  [data-arrow='true'] .articles-carousel-items:first-child {
    padding-left: fn.spacing(13) !important;
  }

  [data-arrow='true'] .articles-carousel-items:last-child {
    padding-right: fn.spacing(13) !important;
  }

  .section {
    padding-top: fn.spacing(8);
    padding-bottom: fn.spacing(8);
    padding-right: 0;
  }

  .title-divider {
    margin-bottom: fn.spacing(8);
  }
}

/*
In this UI, the Achieve content container (1280px max-width including left and right padding) must
be "faked" by calculating the X offset that would be there if the ArticlesCarousel Section included
it (setting contain="true" or the default, see the Section component for more details).
*/
$cardLeft: var.$maximum-site-content-width-narrow - (2 * var.$medium-and-up-gutter-width);

@media screen and (min-width: var.$breakpoint-xl) {
  .articles-carousel-wrapper {
    transform: translateX((calc((100% - $cardLeft) / 2)));

    &[data-arrow='true'] {
      transform: none;
    }
  }
}
